import {useEffect, useState, useRef} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styles from "./Search.module.scss";
import React from "react";
import Xarrow from "react-xarrows";
import { useNavigate } from 'react-router-dom';
import Select from "react-dropdown-select";

const Search = () => {
 const Navigate = useNavigate();
 const [name, setName] = useState("");
 const [nickname, setNickname] = useState("");
 const [email, setEmail] = useState("");
 const [subscr_id, setSubscr] = useState("");
 const [expireAccessDate, setExpireAccessDate] = useState(null);
 const [profileRefs, setProfileRefs] = useState(new Map());
 const [subscrRefs, setSubscrRefs] = useState(new Map());
 const [userResult, setUserResult] = useState(null);
 const [xarrowList, setXarrowList] = useState(null);
 const [cancellationResult, setCancellationResult] = useState(null);
 const [selectedPlan, setSelectedPlan] = useState(null);
 const [expireAccessDateResult, setExpireAccessDateResult] = useState(null);
 const [changeDancePlanResult, setChangeDancePlanResult] = useState(null);
 const dancePlans = ["dancePlanChl", "dancePlanIfNoGender", "dancePlanCommon", "dancePlanCommon27062023", "dancePlanChallenge", "dancePlanChair", "dancePlanCustom1"];
 const dancePlanOptions = [
    {
      value: "dancePlanChl",
      label: 'dancePlanChl'
    },
    {
      value: "dancePlanIfNoGender",
      label: "dancePlanIfNoGender"
    },
   {
         value: "dancePlanCommon",
         label: "dancePlanCommon"
    },
   {
            value: "dancePlanCommon27062023",
            label: "dancePlanCommon27062023"
       },
    {
               value: "dancePlanChallenge",
               label: "dancePlanChallenge"
          },
   {
                  value: "dancePlanChair",
                  label: "dancePlanChair"
             },
  ];
//  const profileRef = useRef(null);
//  const subscrRef = useRef(null);
useEffect(() => {
           if(localStorage.getItem('auth')){
           let authExpire = localStorage.getItem('auth');
           if(authExpire < Date.now()){
            Navigate('/');
             }
           }  else {
                Navigate('/');
               }
        }, []);

  const handleSubmitEmail = async () => {
        setExpireAccessDateResult(null)

        console.log("handleSubmitEmail " + email);
        setCancellationResult(null)
        let searchResult;
        await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/seach_user_by_email", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                body: {
                    email: email,
                }
            }),
        }).then(async (response) => {
            let resultBody = response.json();
            await resultBody.then(async (json) => {
                console.log(json.result)
                searchResult = json.result
                return json.result
            });
        });
        console.log(searchResult)

        let profileIds = [];

        let subscrIds = [];

        for(let u = 0; u < searchResult.users.length; u++){
        const profileId = searchResult.users[u].id;
        profileIds.push(profileId);

        }

        let xarrowListValue = [];
        for(let s = 0; s < searchResult.subscription.length; s++){
          if(profileIds.includes(searchResult.subscription[s].profile_id)){
            const subscrId = searchResult.subscription[s].subscr_id;

            xarrowListValue.push( {"id": searchResult.subscription[s].profile_id + '_' + subscrId, "start" : searchResult.subscription[s].profile_id,  "end": subscrId } );

          }
        }

        setXarrowList(xarrowListValue);

        setUserResult(searchResult)
    };

const handleSubmitName = async () => {
   setExpireAccessDateResult(null)
   setCancellationResult(null)
   let searchResult;
    await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/seach_user_by_email", {
               method: "POST",
               headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json'
               },
               body: JSON.stringify({
                   body: {
                       name: name,
                   }
               }),
           }).then(async (response) => {
               let resultBody = response.json();
               await resultBody.then(async (json) => {
                   console.log(json.result)
                   searchResult = json.result
                   return json.result
               });
           });
           console.log(searchResult)

           let profileIds = [];

           let subscrIds = [];

           for(let u = 0; u < searchResult.users.length; u++){
           const profileId = searchResult.users[u].id;
           profileIds.push(profileId);

           }

           let xarrowListValue = [];
           for(let s = 0; s < searchResult.subscription.length; s++){
             if(profileIds.includes(searchResult.subscription[s].profile_id)){
               const subscrId = searchResult.subscription[s].subscr_id;

               xarrowListValue.push( {"id": searchResult.subscription[s].profile_id + '_' + subscrId, "start" : searchResult.subscription[s].profile_id,  "end": subscrId } );

             }
           }

           setXarrowList(xarrowListValue);

           setUserResult(searchResult)
  };

const handleSetSubscription = async (user) => {
      setCancellationResult(null)
      let setSubscrResult;
              await fetch(" https://vg9yyyq13c.execute-api.us-east-1.amazonaws.com/dev/set_user_web_subscription", {
                  method: "POST",
                  headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      body: {
                          user_id: user,
                          subscription_id: subscr_id
                      }
                  }),
              }).then(async (response) => {
                  let resultBody = response.json();
                  await resultBody.then(async (json) => {
                      console.log(json.result)
                      setSubscrResult = json.result
                      return json.result
                  });
              });

 };

 const handleSetExpireAccessDate = async (user) => {
 console.log(Date.parse(expireAccessDate));
       let expireAccessDateResponce;
               await fetch("https://t2lowguqdl.execute-api.us-east-1.amazonaws.com/dev/set_user_free_access", {
                   method: "POST",
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json'
                   },
                   body: JSON.stringify({
                       body: {
                           id: user,
                           toDate: Date.parse(expireAccessDate)
                       }
                   }),
               }).then(async (response) => {
                   let resultBody = response.json();
                   await resultBody.then(async (json) => {
                       console.log(json)
                       expireAccessDateResponce = json
                       return json
                   });
               });
               setExpireAccessDateResult(expireAccessDateResponce)

  };

  const handleChangeDancePlan = async (user) => {
   console.log(selectedPlan[0]);
         let changeDancePlanResponse;
                 await fetch("https://0pk83toki5.execute-api.us-east-1.amazonaws.com/dev/change_dance_plan", {
                     method: "POST",
                     headers: {
                         'Accept': 'application/json',
                         'Content-Type': 'application/json'
                     },
                     body: JSON.stringify({
                         body: {
                             user: user,
                             plan_id: selectedPlan[0].value
                         }
                     }),
                 }).then(async (response) => {
                     let resultBody = response.json();
                     await resultBody.then(async (json) => {
                         console.log(json)
                         changeDancePlanResponse = json
                         return json
                     });
                 });
                 setChangeDancePlanResult(changeDancePlanResponse)
    };

  const handleSubmitNickname = async (event) => {
     setCancellationResult(null)
     setExpireAccessDateResult(null)
     let searchResult;
         await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/seach_user_by_email", {
             method: "POST",
             headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json'
             },
             body: JSON.stringify({
                 body: {
                    nickname: nickname.toLowerCase(),
                 }
             }),
         }).then(async (response) => {

             let resultBody = response.json();
             await resultBody.then(async (json) => {
                 console.log(json.result)
                 searchResult = json.result
                 return json.result
             });
         });
         console.log(searchResult)

                let profileIds = [];

                let subscrIds = [];

                for(let u = 0; u < searchResult.users.length; u++){
                const profileId = searchResult.users[u].id;
                profileIds.push(profileId);

                }

                let xarrowListValue = [];
                for(let s = 0; s < searchResult.subscription.length; s++){
                  if(profileIds.includes(searchResult.subscription[s].profile_id)){
                    const subscrId = searchResult.subscription[s].subscr_id;

                    xarrowListValue.push( {"id": searchResult.subscription[s].profile_id + '_' + subscrId, "start" : searchResult.subscription[s].profile_id,  "end": subscrId } );

                  }
                }

                setXarrowList(xarrowListValue);

                setUserResult(searchResult)

    };

 const cancelWebSubscription = async (id) => {
        setCancellationResult(null)
        let cancelResult;
        await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/cancel_web_subscription", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                body: {
                    subscriptionId: id,
                }
            }),
        }).then(async (response) => {
            console.log("cancel subscription result");
            let resultBody = response.json();
            await resultBody.then(async (json) => {
                console.log(json)
                cancelResult = json
                return json
            });
        });
        console.log(cancelResult)
        setCancellationResult(cancelResult)
        return cancelResult
    };

  return (
  <>
    <div className={styles.mainContainer}>
      <Tabs>
         <TabList>
           <Tab>Users</Tab>
           <Tab>Subscriptions</Tab>
         </TabList>
         <TabPanel>
           <h2>Search users by email, nickname, name</h2>
               <div>
                 <label>Enter email:
                   <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
                 </label>
                 <button onClick={handleSubmitEmail}>
                   	<span>Send</span>
                 </button>

               </div>
               <div>
                                 <label>Enter nickname:
                                    <input type="text" value={nickname} onChange={(e) => setNickname(e.target.value)}/>
                                 </label>

                                  <button onClick={handleSubmitNickname}>
                                      <span>Send</span>
                                  </button>
                              </div>
                              <div>
                                                 <label>Enter name:
                                                    <input type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                                                </label>

                                                <button onClick={handleSubmitName}>
                                                        <span>Send</span>
                                                </button>
                                             </div>

                           {userResult && <div>
                            <p style={{color:"green", fontSize:'36px'}}> User Profiles:  </p>
                            <div className={styles.subscriptions}>
                            { userResult.users?.map(user  => (
                            <div key={user.id} id={user.id} className={styles.userCard}>

                               <p> User Id: {user.id}  </p>
                               <p> User Name: {user.name}  </p>
                               <p> User Email: {user.email}  </p>
                               <p> User Nickname: {user.nickname}  </p>
                               <p> User Authenticator: {user.authenticator}  </p>
                               <p> Date created: {new Date(user.created_date).toUTCString()}  </p>
                               { user.plan !== null && user.plan !== undefined && <p> Training plan: {user.plan}  </p>}
                               <Select
                                 options={dancePlanOptions}
                                 labelField="label"
                                 valueField="value"
                                 multi={false}
                                 onChange={(values) => setSelectedPlan(values)}
                               />
                                 <button onClick={() => handleChangeDancePlan(user.id)}>
                                         {changeDancePlanResult !== null ? <span>{changeDancePlanResult.updated ? "Updated" : "Error, try again"}</span> : <span>Update plan</span>}
                                 </button>

                               { user.subscriptions !== null && user.subscriptions !== undefined
                                   && <div>
                                   {  user.subscriptions.map(subscr  => (
                                         <div key={subscr.subscr_id} className={styles.userSubscrCard}>
                                          Subscription ID: {subscr.subscr_id}
                                         </div> ))
                                   }
                                     </div>
                               }

                               <p> Last online time: { user.last_online_time !== null && user.last_online_time !== undefined
                               ? new Date(user.last_online_time).toUTCString()
                                : "User didn't log in yet"}  </p>

                               {   userResult.subscription.length === 0 &&  <div>
                                                      <label>Enter subscription id:
                                                      <input type="text" value={subscr_id} onChange={(e) => setSubscr(e.target.value)}/>
                                                      </label>
                                                      <button onClick={() => handleSetSubscription(user.id)}>
                                                      <span>Confirm</span>
                                                     </button>
                                                      </div> }
                                { user.free_access !== null && user.free_access !== undefined
                                                                  && <p> Free access till : { user.free_access !== 1
                                                                 ? new Date(user.free_access).toUTCString()
                                                                      : "Forever"}  </p>}
                               <div>
                                       <label>Select access expire date:
                                       <input type="datetime-local" value={expireAccessDate} onChange={(e) => setExpireAccessDate(e.target.value)}/>
                                       </label>
                                       <button onClick={() => handleSetExpireAccessDate(user.id)}>
                                       {expireAccessDateResult !== null ? <span>{expireAccessDateResult.updated ? "Updated" : "Error, try again"}</span> : <span>Confirm</span>}
                                       </button>
                               </div>
                            </div>
                            ))}
                            </div>
                           <p style={{color:"violet", fontSize:'26px'}}> Subscriptions:   </p>
                           <div className={styles.subscriptions}>
                           { userResult.subscription?.map(subscription  => (

                           <div key={subscription.subscr_id}  className={styles.subscrCard}>
                           <p style={{color:"blue", fontSize: '30px'}}>Price:  {subscription.price} </p>
                           <p>Subscription ID: {subscription.subscr_id} </p>
                           <p>Platform: {subscription.platform} </p>
                           <p>Purchase platform: {subscription.platform} </p>
                           <p>Date purchase: {subscription.date_purchased} </p>
                           <p>Subscription Duration: {subscription.duration} </p>
                           <p id={subscription.subscr_id} >Subscription status: {subscription.status} </p>
                             { ( (subscription.platform === 'stripe' || subscription.platform === 'paypal')
                                && cancellationResult === null && subscription.status !== "inactive")
                                 && <button onClick={() => {
                                               cancelWebSubscription(subscription.subscr_id)}}> Cancel </button> }
                            { ((subscription.platform === 'stripe' || subscription.platform === 'paypal') && cancellationResult)
                               && <div>
                                       <p> Cancellation Result: </p>
                                       <p> {cancellationResult.canceled ? "Cancelled" : "Not cancelled"} </p>
                                  </div>}

                          </div>  ))
                          }
                           </div>


                              {xarrowList && <div>

                             { xarrowList.map(xarrow => (
                               <div key={xarrow["id"]} >

                                <Xarrow path={"straight"} curveness={4} dashness={{ strokeLen: 5, nonStrokeLen: 6, animation: -2 }}
                                  animateDrawing={true}
                                  showHead={true}
                                  start={xarrow["start"]} //can be react ref
                                   end={xarrow["end"]} //or an id
                                />
                             </div>
                             ))}
                             </div>}


                           </div>}
         </TabPanel>
         <TabPanel>
           <h2>Search subscriptions</h2>
         </TabPanel>
       </Tabs>
    </div>
    </>
  );
};
export default Search;